import { useEffect } from 'react'

// Context
import { useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state';

// Services
import siteServices from '/services/site';

const useBranches = () => {
	const { branches, setData } = useGlobalState();

	useEffect(() => {
		if(branches === undefined) {
			setData('branches', null);

			siteServices.getAllBranches().then(branches => {
				setData('branches', branches);
			}).catch(() => {
				setData('branches', undefined);
			})
		}
	}, [branches]);

	return branches || null;
}

export default useBranches;