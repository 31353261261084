import '/assets/styles/partials/mapelement.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import{ useCallback, forwardRef, useEffect, useState, useMemo } from 'react';
import { Marker, GoogleMap, useJsApiLoader } from '@react-google-maps/api';


// Static Assets
import image_marker_icon from '/assets/images/icon/marker-icon.svg';

export const MapElement = forwardRef(({ className: classNameProp, zoom, center, markers: rawMarkers, options, fitBounds, ...props }, ref) => {
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: process.config.googleMaps?.apiKey,
	})

	const [mapInstance, setMapInstance] = useState(null);

	const markers = useMemo(() => {
		return (rawMarkers || []).map(marker => ({
			...marker,
		}))
	}, [rawMarkers])

	const className = useMemo(() => classNames('mapelement', classNameProp), [classNameProp]);

	const onLoad = useCallback(function callback(map) {
		setMapInstance(map);
	}, [markers])

	const onUnmount = useCallback(() => {
		setMapInstance(null);
	}, [])

	useEffect(() => {
		if(fitBounds && mapInstance && markers && markers.length) {
			var bounds = new window.google.maps.LatLngBounds();
			for(const marker of markers) {
				bounds.extend({
					...marker.position ?? {},
				});
			}
			mapInstance.fitBounds(bounds);
		}
	}, [mapInstance, markers, fitBounds])

	const api = useMemo(() => ({
		mapsInstance: mapInstance,
	}), [mapInstance])

	useEffect(() => {
		if (ref) {
			if (typeof ref === 'function') {
				ref(api);
			} else {
				ref.current = api;
			}
		}
	}, [api])

	if(isLoaded) {
		return (
			<div className={className}>
				<GoogleMap
					{...props}
					options={{
						...(options ?? {}),
						styles: process.config.googleMaps?.theme ?? [],
					}}
					mapContainerStyle={{
						width: '100%',
						height: '100%',
					}}
					center={center}
					zoom={zoom}
					onLoad={onLoad}
					onUnmount={onUnmount}
				>
					{markers.map((marker, nth) => {
						return (
							<Marker
								key={nth}
								icon={image_marker_icon}
								{...marker} />
						);
					})}
				</GoogleMap>
			</div>
		);
	}
	return null;
});

MapElement.displayName = 'MapElement';

MapElement.defaultProps = {
	className: '',
	// zoom: 10,
	theme: false,
}

MapElement.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	zoom: PropTypes.number,
	center: PropTypes.object,
	mapOptions: PropTypes.object,
	markers: PropTypes.array,
	theme: PropTypes.oneOf([PropTypes.object, false])
}

export default MapElement;