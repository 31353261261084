import '/assets/styles/pages/contact.scss';
import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

// Functions
import formatPhone from '@wearetla/tla-essentials-tools/functions/format-phone';

// Templates
import { SidemenuTemplate } from '/views/layouts/sidemenu'

// Partials
import { Input, Form, FormFeedback } from '/views/partials/forms';
import Icon from '@wearetla/tla-essentials-tools/partials/icon'
import Btn from '/views/partials/btn'
import MapElement from '/views/partials/mapelement'

// Sections
import SectionHeading from '/views/sections/sidemenu/section-heading';
import Section from '/views/sections/sidemenu/section';

// Context
import { useNavigator, useParams } from '@wearetla/tla-essentials-tools/utilities/navigator'
// import { useGlobalState } from "@wearetla/tla-essentials-tools/utilities/global-state";
import { useHead } from '@wearetla/tla-essentials-tools/utilities/head';
import { useBreakpoints } from '@wearetla/tla-essentials-tools/utilities/breakpoints';

// Services
import siteServices from '/services/site';
import useBranches from '../../hooks/branches';

const parseHead = (activeBranch) => {
	return {
		title: activeBranch ? `${activeBranch.title} İletişim Bilgileri` : 'İletişim',
		description: 'IF Performance Hall şube iletişim bilgileri.',
	}
}

const getBranches = () => {
	return siteServices.getAllBranches();
}

const parseActiveBranch = (branchData, branchSlug) => {
	const activeBranch = (branchData ?? []).find(b => b.slug === branchSlug) ?? branchData?.[0];

	if(activeBranch) {
		return {
			...activeBranch,
			formattedPhone: formatPhone(activeBranch.phone),
		}
	}

	return null;
}

const Contact = () => {
	const { desktop } = useBreakpoints();
	const { setHead } = useHead();

	const [showMap, setShowMap] = useState(false);
	const branchData = useBranches();
	// const [branchData, setBranchData] = useState(pageProps.branchData ?? null);

	// useEffect(() => {
	// 	if(!branchData) {
	// 		getBranches().then((payload) => {
	// 			setBranchData(payload);
	// 		}).catch(() => {
	// 			setError();
	// 		})
	// 	}
	// }, [branchData])

	const { redirect } = useNavigator();
	const { branch: branchSlug } = useParams(['contact']);

	const activeBranch = useMemo(() => {
		return parseActiveBranch(branchData, branchSlug);
	}, [branchData, branchSlug]);
	

	const branchNavItems = useMemo(() => ((branchData ?? []).map((b => ({
		title: b.title,
		href: 'contact',
		active: b.slug === activeBranch?.slug,
		params: { branch: b.slug },
	})))), [activeBranch, branchData]);

	useEffect(() => {
		if(!branchSlug && activeBranch) {
			redirect('contact', { branch: activeBranch.slug })
		}
		else {
			setShowMap(false);
			setHead(parseHead(activeBranch));
		}
	}, [activeBranch, branchSlug]);

	return (
		<SidemenuTemplate
			title="İletişim"
			menuItems={branchNavItems}>
			<Section loading={!activeBranch}>
				{activeBranch &&
					<>
						{desktop &&
							<SectionHeading lined={false} title={activeBranch.title} />
						}

						<div className="contact-info">
							<div className="info-section">
								<Icon name="address" /> {activeBranch.address}
							</div>
							<div className="info-section">
								<Icon name="phone" /> <a href={`tel:${activeBranch.phone}`}>{activeBranch.formattedPhone}</a>
							</div>
							<div className="info-section">
								<Icon name="envelope-dark" /> <a href={`mailto:${activeBranch.email}`}>{activeBranch.email}</a>
							</div>

							{showMap &&
								<MapElement
									className="info-map"
									center={{
										lat: activeBranch.lat,
										lng: activeBranch.lng,
									}}
									zoom={16}
									markers={[{
										position: {
											lat: activeBranch.lat,
											lng: activeBranch.lng,
										},
									}]}
									>
								</MapElement>
							}

							<Btn
								onClick={() => {
									setShowMap(!showMap);
								}}
								icon="map"
								afterIcon={showMap ? 'angle-up' : 'angle-down'}
								className="info-mapbtn primary">
								{showMap ? 'Haritayı Gizle' : 'Haritada Gör'}
							</Btn>
						</div>
					</>
				}
			</Section>

			<ContactForm
				activeBranch={activeBranch}
				branchData={branchData} />
		</SidemenuTemplate>
	)
}

const ContactForm = ({ branchData, activeBranch }) => {
	const [feedbackData, setFeedbackData] = useState(null);
	const [submitting, setSubmitting] = useState(false);
	const [success, setSuccess] = useState(false);
	const [contactSubjects, setContactSubjects] = useState(null);

	const { branchOptions, selectedBranch } = useMemo(() => {
		const branchOptions = (branchData ?? []).map(branch => ({
			...branch,
			value: branch.id,
			label: branch.title,
		}));

		return {
			branchOptions, 
			selectedBranch: branchOptions.find(b => b.id === activeBranch?.id) ?? null,
		}
	}, [branchData, activeBranch]);

	useEffect(() => {
		setContactSubjects(null);
		setFeedbackData(null);
		setSuccess(false);

		if(activeBranch) {
			siteServices.getContactSubjects(activeBranch.id).then(setContactSubjects).catch(setFeedbackData);
		}
	}, [activeBranch])

	const submit = (formFields) => {
		setFeedbackData(null);
		setSubmitting(true);

		siteServices.submitContactForm(formFields).then((payload) => {
			setFeedbackData(payload);
			setSuccess(true);
			setSubmitting(false);
		}).catch((error) => {
			setFeedbackData(error);
			setSubmitting(false);
		});
	}

	return (
		<Section>
			<SectionHeading lined={false} title="İletişim Formu" />

			<Form
				onSubmit={submit}
				className="contact-contactform grid-container">
				{!success &&
					<div className="row">
						<div className="col">
							Formu doldurarak bize ulaş, en kısa zamanda sana geri dönelim ;)
						</div>
					</div>
				}
				<FormFeedback inGrid data={feedbackData} />
				{!success &&
					<>
						<div className="row">
							<div className="col x6">
								<Input
									disabled={submitting || !contactSubjects}
									type="select"
									name="type"
									label="Konu"
									options={contactSubjects}
									value={contactSubjects?.[0]}
									validation={{
										required: 'Bir konu seçmelisiniz.',
									}} />
							</div>
							<div className="col x6">
								<Input
									disabled={!!selectedBranch}
									type="select"
									name="branch_id"
									label="Hangi Şube?"
									options={branchOptions}
									value={selectedBranch}
									validation={{
										required: 'Bir şube seçmelisiniz.',
									}} />
							</div>
						</div>
						<div className="row">
							<div className="col x6">
								<Input
									disabled={submitting}
									type="text"
									name="name"
									label="Ad"
									autoComplete="given-name"
									validation={{
										required: 'Adınızı girmelisiniz.',
									}} />
							</div>
							<div className="col x6">
								<Input
									disabled={submitting}
									type="text"
									name="surname"
									label="Soyad"
									autoComplete="family-name"
									validation={{
										required: 'Soyadınızı girmelisiniz.'
									}} />
							</div>
						</div>
						<div className="row">
							<div className="col x6">
								<Input
									disabled={submitting}
									type="text"
									name="email"
									label="E-Posta"
									autoComplete="email"
									validation={{
										required: 'E-Posta adresinizi girmelisiniz.',
										email: true,
									}} />
							</div>
							<div className="col x6">
								<Input
									disabled={submitting}
									type="phone"
									label="Telefon"
									name="phone"
									autoComplete="tel"
									validation={{
										required: 'Telefon numaranızı girmelisiniz.',
										phone: 'Geçerli bir telefon numarası girmelisiniz.',
									}} />
							</div>
						</div>
						<div className="row">
							<div className="col">
								<Input
									disabled={submitting}
									type="textarea"
									name="message"
									label="Neler oluyor?"
									validation={{
										required: 'Mesajınızı girmelisiniz.',
										minLength: ['Mesajınız en az {length} karakter içermelidir.', 10],
									}} />
							</div>
						</div>
						<div className="row">
							<div className="col x6">
								<Btn
									type="submit"
									disabled={submitting}
									loading={submitting}
									className="form-submitbtn primary big">
									Gönder
								</Btn>
							</div>
						</div>
					</>
				}

				{success &&
					<div className="row">
						<div className="col al-center">
							<Btn
								onClick={() => {
									setSuccess(false);
									setFeedbackData(null);
								}}
								className="primary">Yeniden Gönder</Btn>
						</div>
					</div>
				}
			</Form>
		</Section>
	)
}

Contact.propTypes = {
	pageProps: PropTypes.object
}

Contact.getServerProps = ({ match }) => {
	return new Promise((resolve) => {
		Promise.all([
			getBranches(),
		]).then(([
			branches,
		]) => {
			const activeBranch = parseActiveBranch(branches, match.params.branch);

			resolve({
				sharedData: {
					branches,
				},
				head: parseHead(activeBranch),
			})
		}).catch(() => {
			resolve({
			})
		})
	});
}

export default Contact